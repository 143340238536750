/***
 *
 *   DEBUG
 *   Debug provider to allow the application to enter/exit debug mode,
 *
 **********/

import { useEffect, createContext, useState } from 'react'
import { getOS } from 'utils'

export const DebugContext = createContext()

export function DebugProvider(props) {
  const os = getOS()
  const [debug, setDebug] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') return
    window.addEventListener('keydown', (event) => {
      const isCommandKey = os === 'macos' ? event.metaKey : event.ctrlKey
      if (isCommandKey && event.key === 'd') {
        event.preventDefault()
        setDebug((prev) => !prev)
      }
    })
  }, [os])

  return (
    <DebugContext.Provider
      value={{
        debug,
        setDebug,
      }}
      {...props}
    />
  )
}
