import useCallAPI from 'components/hooks/callApi'
import { useAPI } from 'components/lib'
import { useAccess } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { cleanNestedObject, toggleObjectValues } from 'utils'
import Filter from './filterContainer'

const convertToHierarchicalStructure = (flatData) => {
  const namespaceParam = new URLSearchParams(window.location.search).get('nSpaces')
  const result = {}
  const namespaceMap = {}
  for (const { namespace, name, grouping } of flatData) {
    if (!grouping) continue

    const categories = grouping.split('/')
    let currentLevel = result
    let currentNamespace = namespaceMap

    categories.forEach((category, index) => {
      const isLastCategory = index === categories.length - 1

      currentLevel[category] ??= isLastCategory ? [] : {}
      currentNamespace[category] ??= {}

      currentLevel = currentLevel[category]
      currentNamespace = currentNamespace[category]
    })

    currentLevel[name] = namespaceParam ? false : true
    currentNamespace[name] = namespace
  }
  localStorage.setItem('namespaceMap', JSON.stringify(namespaceMap))
  return result
}

const normalizeStructure = (obj) => {
  if (Array.isArray(obj)) return obj
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, normalizeStructure(value)]))
}

function Filters() {
  const hasAccess = useAccess()

  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState(() => {
    const initialFilters = {
      sortBy: searchParams.get('sortBy') || 'Most Recent',
      filters: {},
      namespaces: {},
    }
    try {
      const filtersParam = searchParams.get('filters')
      const namespaceParam = searchParams.get('nSpaces')
      if (filtersParam) {
        initialFilters.filters = JSON.parse(filtersParam)
      }
      if (namespaceParam) {
        initialFilters.namespaces = JSON.parse(namespaceParam)
      }
    } catch (error) {
      console.error('Error parsing filters from URL:', error)
    }
    return initialFilters
  })

  const [selectedPracticeArea, setSelectedPracticeArea] = useState(searchParams.get('pArea') || '')

  const practiceAreaResponse = useAPI('/api/search/practice-areas')
  const practiceArea = practiceAreaResponse?.data?.filter((area) => hasAccess(area.module_id)) ?? []
  const [callFilters, filtersFromAPI] = useCallAPI({
    url: `/api/search/law-issues?practice_area=${selectedPracticeArea}`,
    method: 'GET',
  })
  // helper functions

  const updateURL = () => {
    const params = new URLSearchParams(window.location.search)
    const cleanFilters = cleanNestedObject(filters.filters)
    const cleanNamespaces = cleanNestedObject(filters.namespaces)
    if (selectedPracticeArea) {
      params.set('pArea', selectedPracticeArea)
    } else {
      params.delete('pArea')
      params.delete('pAreaLabel')
    }
    filters.sortBy !== 'Most Recent' ? params.set('sortBy', filters.sortBy) : params.delete('sortBy')
    Object.keys(cleanFilters).length ? params.set('filters', JSON.stringify(cleanFilters)) : params.delete('filters')
    Object.keys(cleanNamespaces).length
      ? params.set('nSpaces', JSON.stringify(cleanNamespaces))
      : params.delete('nSpaces')

    setSearchParams(params)
  }

  const updateSearchParams = (key, value) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev)
      params.set(key, value)
      return params
    })
  }
  const updateNestedState = (state, path, value) => {
    if (path.length === 1) {
      return { ...state, [path[0]]: value }
    } else {
      return {
        ...state,
        [path[0]]: updateNestedState(state[path[0]] || {}, path.slice(1), value),
      }
    }
  }

  const handleFilterChange = (category, optionPath, isChecked) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      filters: updateNestedState(prevFilters.filters, [category, ...optionPath], isChecked),
    }))
  }

  const handleNamespaceChange = (category, optionPath, isChecked) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      namespaces: updateNestedState(prevFilters.namespaces, [category, ...optionPath], isChecked),
    }))
  }

  const handlePracticeAreaChange = useCallback(
    (event) => {
      const newPracticeAreaKey = event.target.value
      if (newPracticeAreaKey === searchParams.get('pArea')) return
      setSelectedPracticeArea(newPracticeAreaKey)
      setFilters((prev) => ({ ...prev, filters: {}, namespaces: {} }))
      if (newPracticeAreaKey !== '') {
        localStorage.removeItem('namespaceMap')
      }
    },
    [callFilters, searchParams]
  )

  const handleSortChange = useCallback((sortOption) => {
    setFilters((prev) => ({ ...prev, sortBy: sortOption }))
  }, [])

  const handleFilterReset = useCallback(() => {
    setFilters({ sortBy: 'Most Recent', filters: {} })
    setSelectedPracticeArea('')
  }, [])

  // effect hooks

  useEffect(() => {
    if (selectedPracticeArea !== '') {
      callFilters()
    }
  }, [selectedPracticeArea])

  useEffect(() => {
    updateURL()
  }, [filters, selectedPracticeArea])

  useEffect(() => {
    if (filtersFromAPI) {
      updateSearchParams('pAreaLabel', filtersFromAPI.data?.name)
      const hierarchicalData = convertToHierarchicalStructure(filtersFromAPI.data?.namespaces)
      const normalizedNamespaces = normalizeStructure(hierarchicalData)
      setFilters((prev) => ({
        ...prev,
        filters: {
          category: toggleObjectValues(
            filtersFromAPI.data?.categories,
            JSON.parse(searchParams.get('filters'))?.category || {}
          ),
        },
        namespaces: {
          'Document Types': toggleObjectValues(
            normalizedNamespaces,
            JSON.parse(searchParams.get('nSpaces'))?.['Document Types'] || {}
          ),
        },
      }))
    }
  }, [filtersFromAPI])

  return (
    <div>
      {/* <button className="ml-auto underline text text-sm text-[#475569]" onClick={handleFilterReset}>
          Reset // FIXME: uncomment when we have more practice areas
        </button> */}

      <Filter
        filters={filters}
        selectedPracticeArea={practiceArea?.find((area) => area.slug === selectedPracticeArea)?.name || ''}
        onFilterChange={handleFilterChange}
        onNamespaceChange={handleNamespaceChange}
        onRemovePracticeArea={() => handlePracticeAreaChange({ target: { value: '' } })}
        practiceArea={practiceArea}
        onPracticeAreaChange={handlePracticeAreaChange}
        onSortChange={handleSortChange}
      />
    </div>
  )
}

export default Filters
