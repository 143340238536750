import { cn } from 'utils'

const TagBadge = ({ label = 'Early Access', className = '' }) => {
  return (
    <span
      className={cn(
        className,
        'md:ml-1 bg-gray-50 text-[9px] leading-[1.22] font-semibold text-gray-600 py-1 px-2 uppercase rounded-lg shadow-sm border border-gray-300 tracking-wide'
      )}
    >
      {label}
    </span>
  )
}

export { TagBadge }
