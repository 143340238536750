import React, { useState, useRef } from 'react'
import {
  ChatInputForm,
  ConversationDisplay,
  ChatDisclaimer,
  useConversation,
  useConversationAutoScroll,
} from 'components/lib'

import { nzTaxDepreciation } from './definitions.js'
import { Icon } from 'components/lib'

import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import ChatHistory from 'components/ai/chatHistory.js'
import { chatTypeIds } from '@lawcyborg/packages'

export function DepFinder() {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const industries = [
    `Agriculture, horticulture and aquaculture`,
    `Audio and video recording studios and professional photography`,
    `Bakeries`,
    `Battery manufacturing`,
    `Brewing, winemaking and distilleries`,
    `Cable making`,
    `Cement manufacturing`,
    `Chemical plant (including soap, detergent, paint, glue, starch, colour, personal products and fertiliser)`,
    `Cigarette manufacturing`,
    `Cleaning, refuse and recycling`,
    `Concrete and plaster`,
    `Contractors, builders and quarrying`,
    `Dairy plant`,
    `Electrical and electronic engineering (for test equipment see also Asset category: "Scientific and laboratory equipment")`,
    `Engineering (including automotive)`,
    `Fishing (see also "Meat and fish processing")`,
    `Food processing`,
    `Footwear manufacturing`,
    `Glass`,
    `Hotels, motels, restaurants, cafes, taverns and takeaway bars`,
    `Laundry`,
    `Leisure`,
    `Manufacturers (not elsewhere specified)`,
    `Meat and fish processing`,
    `Medical and medical laboratory`,
    `Metal industries (primary) and foundries`,
    `Milling (including grain handling and seed cleaning)`,
    `Mining (see also "Contractors, builders and quarrying")`,
    `Oil and gas industry`,
    `Packaging (excluding plastic packaging)`,
    `Pharmaceuticals`,
    `Plastics`,
    `Pottery, tile and brick making`,
    `Power generation and electrical reticulation systems`,
    `Printing and photographic (see also "Audio and video recording studios and professional photography")`,
    `Pulp and paper manufacturing`,
    `Residential rental property chattels`,
    `Rubber and tyre manufacturing`,
    `Shops`,
    `Tanning and fellmongering`,
    `Telecommunications (see also "Telephone systems" under Asset category: "Office equipment and furniture")`,
    `Textile, garment and carpet manufacturing`,
    `Timber and joinery industries`,
    `Undersea maintenance (where equipment used under salt water or on a maintenance barge on salt water)`,
  ] // Replace with actual industries

  const {
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    loadingConversation,
    canSend,
    dropdownValue,
    setDropdownValue,
    conversations,
    loadConversation,
    conversationId,
    branch,
    swapBranch,
    stopStreaming,
    loadConversations,
    historyCached,
  } = useConversation({ ...nzTaxDepreciation, practiceArea: chatTypeIds.DEPRECIATION })
  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative overflow-x-hidden">
      <Tippy
        touch={false}
        content="Inland Revenue's general depreciation rates guide (IR265)"
        animation="shift-away"
        arrow={roundArrow}
        inertia={true}
      >
        <div
          className="ml-4 cursor-pointer absolute hidden lg:inline w-[4rem] h-[4rem] mt-5 bg-white rounded-full mr-4 drop-shadow-2xl border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20"
          onClick={() => window.open('https://docs.lawcyborg.com/source/IR265.pdf', '_blank')}
        >
          <Icon className="mx-auto my-4" image="file-text" size={30} color="#474747" />
        </div>
      </Tippy>

      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%]  no-scrollbar" id="chat">
        <ChatHistory
          enableRefresh={exchanges.length}
          loadConversation={loadConversation}
          currentConversationId={conversationId}
          conversations={conversations}
          bgHexColor={'#f5f5f5'}
          onOpen={loadConversations}
          loading={!historyCached}
          isStreaming={!loading && !canSend}
        />
        {!(loadingConversation || exchanges.length) && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={nzTaxDepreciation.disclaimers}
          />
        )}

        {errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          loadingConversation={loadingConversation}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
        />
      </div>

      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        {/* <RefreshButton enabled={exchanges.length} /> */}

        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          dropdownValue={dropdownValue}
          setDropdownValue={setDropdownValue}
          dropdownOptions={industries}
          placeholderText="Describe an asset..."
          submitIcon="search"
        />
      </div>
    </div>
  )
}
