import { countries, moduleIds } from '@lawcyborg/packages'
import MultiSelect from 'components/form/multiSelect/multiSelect'
import useCallAPI from 'components/hooks/callApi'
import {
  AccountNav,
  Animate,
  Button,
  Card,
  EmailInput,
  TitleRow,
  useAPI,
  useNavigate,
  usePermissions,
  ViewContext,
} from 'components/lib'
import { useContext, useMemo, useState } from 'react'
import { cn, GST } from 'utils'
import { OrderSummary } from '../billing/purchaseProducts'
import { currentInfoModulesSet } from 'views/dashboard/definitions'
import { useAuth } from 'app/auth'

const INITIAL_FORM_DATA = {
  email: { value: '', valid: undefined },
  permission: { value: [], valid: true },
  licenses: { value: [], valid: undefined },
  plan: { value: [], valid: undefined },
}

const InviteUsersForm = ({ productResponse }) => {
  const navigate = useNavigate()
  const [additionalLicenses, setAdditionalLicenses] = useState([])
  const context = useContext(ViewContext)
  const { accounts } = useAuth()
  const [formData, setFormData] = useState([{ ...INITIAL_FORM_DATA }])
  const permissions = usePermissions()
  const planSet = new Set([moduleIds.ESSENTIAL_PLAN, moduleIds.ELITE_PLAN])
  const [callInviteUsers, _, loading] = useCallAPI({
    url: '/api/invite/bulk',
    method: 'POST',
  })
  const permissionOptions = permissions?.data?.list?.filter(
    (x) => x.value !== 'owner' && x.value !== 'developer' && x.value !== 'manager_core_concept'
  )

  const totalRequiredLicence = additionalLicenses?.reduce((acc, licence) => {
    return {
      ...acc,
      requiredLicenses: (acc.requiredLicenses || 0) + licence.requiredLicenses,
    }
  }, [])
  const gstRate = GST[accounts[0].country]
  const calculateAdditionalLicenses = (currentFormData = formData) => {
    if (!currentFormData) return
    const moduleUsage = currentFormData.reduce((usage, entry) => {
      entry.licenses?.value?.forEach((moduleId) => {
        usage[moduleId] = (usage[moduleId] || 0) + 1
      })
      entry.plan?.value?.forEach((planId) => {
        usage[planId] = (usage[planId] || 0) + 1
      })
      return usage
    }, {})

    const licenseRequirements = [
      ...(productResponse.data.informationModules || []),
      ...(productResponse.data.products || []),
    ]
      .map((product) => {
        const totalAvailable = product.purchased_quantity - product.assigned_license
        const currentlySelected = moduleUsage[product.module_id] || 0
        const requiredLicenses = Math.max(currentlySelected - totalAvailable, 0)
        return requiredLicenses > 0 ? { ...product, requiredLicenses } : null
      })
      .filter(Boolean)
    return licenseRequirements
  }

  const handleChange = (index, field, value, valid) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index][field] = { value, valid }

      // If plan is cleared, also clear licenses
      if (field === 'plan' && value.length === 0) {
        updatedFormData[index]['licenses'] = { value: [], valid: undefined }
      }
      return updatedFormData
    })
    if (field === 'licenses' || field === 'plan') setAdditionalLicenses(() => calculateAdditionalLicenses())
  }

  const addFormFields = () => {
    setFormData((prev) => [...prev, { ...INITIAL_FORM_DATA }])
  }

  const removeFormFields = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = prevFormData.filter((_, i) => i !== index)
      setAdditionalLicenses(() => calculateAdditionalLicenses(updatedFormData))
      return updatedFormData
    })
  }

  const validate = useMemo(
    () => formData.every((entry) => entry.email?.valid && entry.email.value.length > 0),
    [formData]
  )
  const handleSubmit = async (e) => {
    if (!validate) return

    const products = additionalLicenses?.map(({ module_id, price_id, requiredLicenses, purchased_quantity }) => ({
      plan: price_id,
      quantity: requiredLicenses + purchased_quantity,
      module_id,
    }))

    const users = formData.map((user) => ({
      email: user.email.value,
      licenses: [...(user.licenses.value || []), ...(user.plan.value || [])],
      permission: user.permission.value.length > 0 ? user.permission.value[0] : 'user',
    }))
    const res = await callInviteUsers({
      requestData: {
        users,
        products,
      },
    })
    if (res && !res.isAxiosError) {
      context.notification.show('Invitation sent successfully', 'success', true)
      navigate('/account/users')
    }
  }

  const getPlanOptions = () => {
    if (!formData || !productResponse?.data?.products) return []
    return productResponse?.data?.products?.reduce((availableOptions, option) => {
      if (!planSet.has(option.module_id)) return availableOptions
      availableOptions.push({
        label: option.name,
        value: option.module_id,
      })

      return availableOptions
    }, [])
  }
  const getAvailableLicenses = () => {
    if (!formData || !productResponse?.data?.informationModules) return []

    return productResponse?.data?.informationModules?.reduce((availableOptions, option) => {
      if (!currentInfoModulesSet.has(option.module_id)) return availableOptions
      availableOptions.push({
        label: option.name,
        value: option.module_id,
        badge: option.module_id !== moduleIds.TAX ? 'early access' : '',
      })

      return availableOptions
    }, [])
  }

  const calculateAdditionalLicenceValue = useMemo(() => {
    return additionalLicenses.reduce((sum, license) => {
      return sum + parseFloat(license.price_per_unit) * license.requiredLicenses
    }, 0)
  }, [additionalLicenses])

  return (
    <form onSubmit={handleSubmit} noValidate>
      {formData.map((entry, index) => (
        <div key={index} className="form-entry border-b last:border-b-0 ">
          <div className="flex gap-2 w-full my-2 max-sm:flex-col">
            <div className="flex-1">
              <EmailInput
                label="Email"
                id={`email-${index}`}
                name={`email-${index}`}
                placeholder="Enter your email"
                value={entry.email.value}
                valid={entry.email.valid}
                onChange={(input, value, valid) => handleChange(index, 'email', value, valid)}
                required
              />
            </div>
            <MultiSelect
              singleSelect
              options={permissionOptions || []}
              className="flex-1 "
              label="Permission"
              selectedValues={entry.permission.value?.length > 0 ? entry.permission.value : ['user']}
              onValueChange={(value) => handleChange(index, 'permission', value, value.length > 0)}
              placeholder="Select permission"
            />
            <div className="px-2" />
          </div>
          <div className="flex gap-2 items-center max-sm:flex-col">
            <MultiSelect
              singleSelect
              options={getPlanOptions()}
              className="flex-1 max-sm:w-full"
              id={`plan-${index}`}
              label="Plan"
              selectedValues={entry.plan?.value}
              // valid={entry.plan?.valid}
              errorMessage="Please select a plan"
              onValueChange={(value) => handleChange(index, 'plan', value, value.length > 0)}
              placeholder="Select plan"
            />
            {entry.plan?.value && entry.plan?.value?.length > 0 && (
              <MultiSelect
                options={getAvailableLicenses()}
                className="flex-1 max-sm:w-full"
                id={`licences-${index}`}
                label="Practice Area License"
                errorMessage="Please select a practice area"
                // valid={entry.licenses.valid}
                selectedValues={entry.licenses.value}
                onValueChange={(value) => handleChange(index, 'licenses', value, value.length > 0)}
                placeholder="Select practice area"
              />
            )}
            <Button
              className="mt-3"
              icon="trash"
              disabled={formData.length <= 1}
              iconSize={20}
              iconColor={formData?.length > 1 ? '#EF4444' : '#ef444468'}
              action={() => removeFormFields(index)}
            />
          </div>
        </div>
      ))}
      <Button
        textOnly
        icon="plus"
        disabled={formData.length >= 10}
        className={cn(' font-semibold mt-4 mb-8', formData.length < 10 ? '!text-brand-500' : '!text-brand-500/50')}
        text="Add"
        action={addFormFields}
      />
      {additionalLicenses.length > 0 && totalRequiredLicence.requiredLicenses > 0 && (
        <OrderSummary
          className="pb-4 border-b"
          totalItems={totalRequiredLicence.requiredLicenses}
          totalValue={calculateAdditionalLicenceValue}
          gst={calculateAdditionalLicenceValue * gstRate}
          orderTitle="Additional Purchase Summary"
        />
      )}
      {additionalLicenses.length > 0 && totalRequiredLicence.requiredLicenses > 0 && (
        <p className="mt-2 text-sm">
          Note: Any extra licences already held on your account will be assigned rather than purchased.
        </p>
      )}
      <Button
        small
        className="mt-4"
        text={'Submit'}
        action={handleSubmit}
        color="brand3"
        loading={loading}
        disabled={!validate}
      />
    </form>
  )
}

export const Invite = () => {
  const productResponse = useAPI('/api/products-list')

  return (
    <>
      <AccountNav />
      <Animate>
        <TitleRow title="Invite Users">
          <Button small text="Back" goto="/account/users" color="brand3" />
        </TitleRow>
        <Card loading={productResponse?.loading}>
          <InviteUsersForm productResponse={productResponse} />
        </Card>
      </Animate>
    </>
  )
}

export default Invite
