import { Label, ClassHelper } from 'components/lib'
import Style from './checkbox.tailwind.js'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'

export function Checkbox(props) {
  const css = ClassHelper(Style, {
    input: true,
    className: props.className,
  })

  const checkbox = (
    <div className="w-full">
      <input
        type="checkbox"
        name={props.name}
        id={props.option}
        value={props.optionValue || props.option}
        className={css}
        checked={props.checked ? 'checked' : ''}
        onChange={(e) => props.callback(props.index, props.checked, props.optionValue || props.option)}
      />

      {props.option && (
        <Label
          text={props.option}
          required={props.required}
          for={props.option}
          className={`${Style.label} ${props.labelClassName}`}
        />
      )}
    </div>
  )
  return props.hint ? (
    <Tippy touch={false} content={props.hint} animation="shift-away" arrow={roundArrow} inertia={true}>
      {checkbox}
    </Tippy>
  ) : (
    checkbox
  )
}
