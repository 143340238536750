import { chatTypeIds, moduleIds, practiceAreaIds, practiceAreas } from '@lawcyborg/packages'

export const namespacesPinecone = {
  nz: {
    tax: {
      Legislation: [
        { 'Income Tax Act 2007': 'nz_tax_income_tax_act' },
        { 'Tax Administration Act 1994': 'nz_tax_tax_administration_act' },
        { 'Goods and Services Tax Act 1985': 'nz_tax_goods_and_services_tax_act' },
        { 'Companies Act 1993': 'nz_tax_companies_act' },
      ],
      'Tax Technical Publications': [
        { 'Interpretation Statements': 'nz_tax_interpretation_statements' },
        { 'Interpretation Guidelines': 'nz_tax_interpretation_guidelines' },
        { 'Issue Papers': 'nz_tax_issue_papers' },
        { "Questions We've Been Asked": 'nz_tax_questions_weve_been_asked' },
        { Determinations: 'nz_tax_determinations' },
        { 'Fact Sheets': 'nz_tax_fact_sheets' },
        { 'General Articles': 'nz_tax_general_articles' },
        { 'Operational Guidelines': 'nz_tax_operational_guidelines' },
        { 'Operational Positions': 'nz_tax_operational_positions' },
        { 'Revenue Alerts': 'nz_tax_revenue_alerts' },
        { Rulings: 'nz_tax_rulings' },
        { 'Technical Decision Summaries': 'nz_tax_technical_decision_summaries' },
        { 'Standard Practice Statements': 'nz_tax_standard_practice_statements' },
        { 'Operational Statements': 'nz_tax_operational_statements' },
      ],
      'Tax Policy Publications': [
        { 'Bill Commentaries': 'nz_tax_bill_commentaries' },
        { 'Special Reports': 'nz_tax_special_reports' },
        { 'Officials Reports': 'nz_tax_officials_reports' },
        { 'Information Releases': 'nz_tax_information_releases' },
        { 'Consultation Documents': 'nz_tax_consultation_documents' },
        { 'Regulatory Impact Statements': 'nz_tax_regulatory_import_statements' }, // Overwritten as 'Regulatory Impact Statements'
        { 'Other Policy Publications': 'nz_tax_other_policy_publications' },
      ],
      Cases: [
        { 'Taxation Review Authority': 'nz_tax_taxation_review_authority' },
        { 'High Court Cases': 'nz_tax_high_court' },
        { 'Court of Appeal Cases': 'nz_tax_court_of_appeal' },
        { 'Supreme Court Cases': 'nz_tax_supreme_court' },
      ],
      'Tax Treaties': [{ 'Double Tax Agreements': 'nz_tax_double_tax_agreements' }],
      'IR Procedures': [{ 'Forms and Guides': 'nz_tax_forms_and_guides' }],
    },
  },
}

export const namespacesPostgres = {
  nz: {
    tax: {
      Legislation: [
        { 'Income Tax Act 2007': 'nz_legislation_income_tax_act_2007' },
        { 'Tax Administration Act 1994': 'nz_legislation_tax_administration_act_1994' },
        { 'Goods and Services Tax Act 1985': 'nz_legislation_goods_and_services_tax_act_1985' },
        { 'Companies Act 1993': 'nz_legislation_companies_act_1993' },
      ],
      'Tax Technical Publications': [
        { 'Interpretation Statements': 'nz_tax_technical_interpretation_statement' },
        { 'Interpretation Guidelines': 'nz_tax_technical_interpretation_guideline' },
        { 'Issue Papers': 'nz_tax_technical_issue_paper' },
        { "Questions We've Been Asked": 'nz_tax_technical_questions_weve_been_asked' },
        { Determinations: 'nz_tax_technical_determination' },
        { 'Fact Sheets': 'nz_tax_technical_fact_sheet' },
        { 'General Articles': 'nz_tax_technical_general_article' },
        { 'Operational Guidelines': 'nz_tax_technical_operational_guideline' },
        { 'Operational Positions': 'nz_tax_technical_operational_position' },
        { 'Revenue Alerts': 'nz_tax_technical_revenue_alert' },
        { Rulings: 'nz_tax_technical_binding_ruling' },
        { 'Technical Decision Summaries': 'nz_tax_technical_technical_decision_summary' },
        { 'Standard Practice Statements': 'nz_tax_technical_standard_practice_statement' },
        { 'Operational Statements': 'nz_tax_technical_operational_statement' },
      ],
      'Tax Policy Publications': [
        { 'Bill Commentaries': 'nz_tax_policy_bill_commentary' },
        { 'Special Reports': 'nz_tax_policy_special_report' },
        { 'Officials Reports': 'nz_tax_policy_officials_report' },
        { 'Information Releases': 'nz_tax_policy_information_release' },
        { 'Consultation Documents': 'nz_tax_policy_consultation_document' },
        { 'Regulatory Impact Statements': 'nz_tax_policy_regulatory_impact_assessment' },
        { 'Other Policy Publications': 'nz_tax_policy_other_publications' },
      ],
      // LV commented out namespaces not implemented in Postgres yet
      Cases: [
        // { 'Taxation Review Authority': 'nz_nzlii_taxation_review_authority' },
        { 'High Court Cases': 'nz_nzlii_high_court' },
        { 'Court of Appeal Cases': 'nz_nzlii_court_of_appeal' },
        { 'Supreme Court Cases': 'nz_nzlii_supreme_court' },
      ],
      // 'Tax Treaties': [{ 'Double Tax Agreements': 'nz_tax_double_tax_agreements' }],
      'IR Procedures': [{ 'Forms and Guides': 'nz_ird_forms_and_guides_forms_and_guides' }],
    },
  },
}

const createNamespaceObject = (namespaceCategories) => {
  const processNamespaceArray = (namespaceArray) => {
    return namespaceArray.reduce((obj, item) => {
      const [key, value] = Object.entries(item)[0] // Get key/value from item
      return { ...obj, [key]: { value, checked: false } }
    }, {})
  }

  return Object.entries(namespaceCategories).reduce((categoriesObj, [category, namespaceArray]) => {
    categoriesObj[category] = processNamespaceArray(namespaceArray)
    return categoriesObj
  }, {})
}

export const PaDisclaimerTemplate = [
  {
    title: 'Examples',
    icon: { image: 'message-square' },
    content: [`Example 1`, `Example 2`, `Example 3`],
    isClickable: true,
  },
  {
    title: 'Capabilities',
    icon: { image: 'zap' },
    content: [
      'Has extensive knowledge of New Zealand law.',
      'Allows for follow-up questions and explanations, including the ability to explain in diagram form.',
      'Guides the user to further reading to ensure accuracy.',
    ],
  },
  {
    title: 'Limitations',
    icon: { image: 'alert-triangle' },
    content: [
      'The user has a responsibility to check the sources and accuracy.',
      'Currently is limited as to the number of sources it can consider.',
      'May struggle to complete complex calculations accurately.',
    ],
  },
]

export const createDiscliamer = (disclaimerTemplate, columms) => {
  const disclaimer = disclaimerTemplate.map((columnTemplate) => {
    const column = columms.find((item) => item.title === columnTemplate.title)
    return { ...columnTemplate, ...column }
  })
  return disclaimer
}

export const disclaimers = {
  nz: {
    [chatTypeIds.GENERAL]: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: [
          "How can I get rid of a blank page on word that won't disappear?",
          'Write me an excel formula that finds the ten largest numbers in a list',
          'Draft an email for my boss, Mary, asking for leave tomorrow',
          'What is the word for when two things have a contrasting effect?',
          'Could you code an Excel macro to remove duplicates from a sheet? ',
          'What are some synonyms for the word “furthermore”?',
          'How do I politely say to a client that I’m unable to help due to a conflict?',
          'What is an exciting way to tell people that I’m fun?',
          'Please give me some pub quiz team names using the name “Paul”',
          'How can I export a whole notebook from OneNote?',
          'How do I change my status on Teams? Also suggest a funny status',
          'How do I use mail merge on Outlook? Do I need word?',
          'Write me a plan to send my bookmarks to my colleague',
          'How do I access the master slides on PowerPoint?',
          'What happens if I don’t get enough CPD hours in a year?',
          'Write me a nice email to the Law Cyborg team thanking them',
          'Give me five puns using the same “Sarah” for my colleagues',
          'Explain the blockchain to me like I am 5 years old',
        ],
        isClickable: true,
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          'Has extensive general knowledge, including of Word and Excel.',
          'Allows for follow-up questions and explanations.',
          'Can reformat data or rewrite emails in perfect UK English.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'The user has a responsibility to check the accuracy of any output.',
          'Currently has a limited input capability.',
          'May struggle to complete complex calculations accurately.',
        ],
      },
    ],
    depreciation: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: ['Mattress', 'XPS 15', '收银机'],
        isClickable: true,
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          "Classifies assets in accordance with Inland Revenue's guidelines.",
          'Finds rates based on meaning.',
          'Recognises industry-specific asset descriptions.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'The user has a responsibility to check the accuracy of any output.',
          'Currently has trouble identifying when default rates should be used.',
          'This tool is not conversational.',
        ],
      },
    ],
    docUpload: [
      {
        title: 'Examples',
        icon: { image: 'message-square' },
        content: [
          'Please critique this advice. Tell me what can be improved.',
          'What does the annual report say about tax governance?',
          "How do previous years' EBITDA compare?",
        ],
      },
      {
        title: 'Capabilities',
        icon: { image: 'zap' },
        content: [
          'Allows for the secure upload and questioning of documents.',
          'Can create lists, summaries or compare parts of documents.',
          'Has general knowledge and reasoning capabilities.',
        ],
      },
      {
        title: 'Limitations',
        icon: { image: 'alert-triangle' },
        content: [
          'This AI does not have tax technical knowledge yet.',
          'This AI does not provide citations yet.',
          'If chat history is disabled, documents are deleted as soon as you leave the page.',
        ],
      },
    ],
    [practiceAreaIds.TAX]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          'What is thincap? Does it apply to NZ wholly-owned companies?',
          'What provisions should I look at when considering whether bank fees are interest under the ITA?',
          'Please prepare an email explaining s CB 6A and why it was introduced',
          'When considering FIFs, where should I look in the Act?',
          'How do I work out whether an arrangement is tax avoidance?',
          'Draft a warm email to Emma, explaining the penalties for tax shortfall',
          'What is the section of the TAA which relates to late filing penalties?',
          'Take me through calculating total assessable income',
          'How do I calculate the value of accommodation for tax?',
          "Tell me ten NZ-tax specific jokes and explain why they're funny",
          'When should I worry about the FIF rules? Tell me in rhyme',
          'What does "money" mean in s EW 3 / the FA rules?',
          'Who must pay provisional tax again? Does a loss-making startup?',
          'What is the point of the controlled foreign company rules?',
          'Should a small company worry about the hybrid rules?',
          'Draft an email to Christopher explaining our withholding tax regime',
          'Explain s EC 45 and the bloodstock valuation rules',
          'When is a motor vehicle a fringe benefit? when is public transport?',
        ],
      },
    ]),
    [practiceAreaIds.EMPLOYMENT]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What specific clauses must be included in a NZ EA to comply with the ERA?`,
          `What initial steps should I take in responding to a claim of unjustified dismissal?`,
          `What should I consider when drafting a remote work policy which complies with our H&S regulations? `,
        ],
      },
    ]),

    [practiceAreas.personalInjuryAndAccidentCompensation.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are some cases interpreting "work-related gradual process" under Accident Compensation Act 2001 s 30?`,
          `How do courts distinguish "accident" from "treatment injury" in surgical complications?`,
          `What are ACC review criteria for "incapacity" with pre-existing conditions (Schedule 1, cl 32)? `,
        ],
      },
    ]),
    [practiceAreas.willsAndSuccession.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `How does the PRA limit testamentary freedom in New Zealand?`,
          `What is required for a will to be valid in NZ, including any recent case law on testamentary capacity?`,
          `What are the legal implications of appointing a digital executor in a will under current New Zealand law?`,
        ],
      },
    ]),
    [practiceAreas.trustsAndEquity.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the current legal requirements to create an express trust in New Zealand?`,
          `What are the default duties of trustees of an express trust that can be modified or excluded?`,
          `What actions can a beneficiary take if a trustee takes an action that is a breach of trust under the Trusts Act 2019?`,
        ],
      },
    ]),
    [practiceAreas.propertyLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `When may a lessor inspect or otherwise enter a leased property that is occupied by the lessor?`,
          `What is required in order to create an easement over land?`,
          `What duties does a mortgagee owe when exercising the power to sell a mortgaged property?`,
        ],
      },
    ]),
    [practiceAreas.commercialAndContractLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What remedies can be granted to a buyer where a seller breaches the terms of the contract for the sale of goods?`,
          `What remedies can be granted where there is a shared mistake in a contract?`,
          `What is required for there to be a valid contract of sale between two parties?`,
        ],
      },
    ]),
    [practiceAreas.companyAndFinancialMarketsLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `When would a director of a company be considered to be self-interested regarding a transaction which the company is a party to?`,
          `What would be considered to be misleading or deceptive conduct in relation to financial markets?`,
          `What obligations does a company have to keep transaction records for the purposes of the Anti-Money Laundering and Countering Financing of Terrorism Act 2009?`,
        ],
      },
    ]),
    [practiceAreas.bankruptcyAndInsolvency.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What is the process for applying for a DRO?`,
          `How does liquidation differ from voluntary administration?`,
          `What are the consequences of being adjudicated bankrupt in New Zealand?`,
        ],
      },
    ]),
    [practiceAreas.buildingAndConstructionLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What happens if no payment schedule is provided under the Construction Contracts Act?`,
          `How does the Act protect subcontractors’ retention money?`,
          `How can an adjudicator’s decision be enforced under the Act?`,
        ],
      },
    ]),
    [practiceAreas.alternativeDisputeResolution.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `How does the Arbitration Act 1996 ensure confidentiality during arbitral proceedings?`,
          `What are the mandatory and optional rules for arbitration agreements under the Act?`,
          `Under what circumstances can the High Court intervene in arbitral proceedings?`,
        ],
      },
    ]),
    [practiceAreas.insuranceLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the requirements for obtaining a licence as an insurer under the 2010 Act?`,
          `What are the solvency and risk management obligations for licensed insurers in New Zealand?`,
          `What penalties apply for non-compliance with prudential supervision regulations?`,
        ],
      },
    ]),
    [practiceAreas.iPAndTechnologyLawInNZ.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the absolute grounds for refusing a trade mark under the Act?`,
          `What remedies are available for copyright infringement under the Copyright Act 1994?`,
          `What are the key requirements for a software patent under the Patents Act 2013?`,
        ],
      },
    ]),
    [practiceAreas.mediaAndPrivacyLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the requirements for honest opinion?`,
          `What does privacy principle 3 do? Please give me a real-life example.`,
          `What procedural rules apply to cases involving defamation?`,
        ],
      },
    ]),
    [practiceAreas.familyLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What factors does the court consider for child custody decisions?`,
          `What assets are included in the division of relationship property?`,
          `What is the process for applying for a Protection Order in New Zealand?`,
        ],
      },
    ]),
    [practiceAreas.criminalLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What should I know about sentencing for a first-time offender for burglary?`,
          `What are the elements required to prove a theft charge?`,
          `What are the procedural steps for filing an appeal against a conviction?`,
        ],
      },
    ]),
    [practiceAreas.evidenceLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key requirements for the admissibility of hearsay evidence?`,
          `How can privileged communications be protected during discovery in a civil case?`,
          `What are the rules surrounding expert witness testimony under New Zealand law?`,
        ],
      },
    ]),
    [practiceAreas.courtPracticeAndProcedure.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the requirements for filing an interlocutory application in the High Court?`,
          `How do I prepare an affidavit that complies with New Zealand court rules?`,
          `What are the time limits for serving a statement of claim under New Zealand law?`,
        ],
      },
    ]),
    [practiceAreas.environmentAndResources.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key steps to apply for a resource consent under the RMA?`,
          `How do I challenge a local council decision under the RMA?`,
          `What obligations does a business have under the National Policy Statement for Freshwater Management?`,
        ],
      },
    ]),
    [practiceAreas.localGovernmentLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the statutory obligations for public consultation under the LGA 2002?`,
          `How can a council comply with the principles of decision-making in section 14 of the LGA?`,
          `What remedies are available if a local authority exceeds its powers under the LGA 2002?`,
        ],
      },
    ]),
    [practiceAreas.transportLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key obligations for commercial vehicle operators under the Land Transport Act 1998?`,
          `How should liability be determined in a multi-vehicle accident involving potential negligence?`,
          `What regulations govern the licensing of rideshare services in New Zealand?`,
        ],
      },
    ]),
    [practiceAreas.administrativeLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key grounds for judicial review in New Zealand?`,
          `What is the process for seeking judicial review under the Judicial Review Procedure Act 2016?`,
          `What considerations are relevant when challenging a public body's decision on procedural grounds?`,
        ],
      },
    ]),
    [practiceAreas.fisheriesLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key requirements for obtaining a commercial fishing permit in New Zealand?`,
          `What penalties apply for breaches of the quota management system under the Fisheries Act?`,
          `How does the Fisheries Act 1996 regulate customary fishing rights for Māori communities?`,
        ],
      },
    ]),
    [practiceAreas.maoriLawResearchAreas.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `How can tikanga Māori be incorporated into legal proceedings under current NZ law?`,
          `What are the key legal principles governing Māori land succession under Te Ture Whenua?`,
          `How do the principles of the Treaty of Waitangi impact decision-making in public law?`,
        ],
      },
    ]),
    [practiceAreas.healthLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the legal obligations of a healthcare provider when a patient's privacy is breached?`,
          `What steps must a medical professional take to comply with informed consent requirements in NZ?`,
          `How does the Health Practitioners Disciplinary Tribunal process work for addressing complaints?`,
        ],
      },
    ]),
    [practiceAreas.humanRightsAndImmigration.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What criteria must be met for a successful claim under the Human Rights Act 1993?`,
          `What are the key considerations for filing a residency application under the Immigration Act?`,
          `How should I advise a client facing deportation on humanitarian grounds?`,
        ],
      },
    ]),
    [practiceAreas.charitiesLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the key compliance obligations for a charity under the Charities Act 2005?`,
          `How should an incorporated society amend its constitution to comply with the Incorporated Societies Act 2022?`,
          `What are the reporting requirements for Tier 3 charities in New Zealand?`,
        ],
      },
    ]),
    [practiceAreas.legalAid.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What financial criteria must an applicant meet to qualify for legal aid?`,
          `How can I assist a client whose legal aid application has been declined?`,
          `What is the process for reviewing a decision made by Legal Aid Services?`,
        ],
      },
    ]),
    [practiceAreas.socialWelfareLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What are the eligibility criteria for receiving Jobseeker Support in New Zealand?`,
          `How does the Social Security Act 2018 regulate hardship assistance for beneficiaries?`,
          `What are the obligations of a client when receiving a benefit under the Social Security Act?`,
        ],
      },
    ]),
    [practiceAreas.consumerRightsLaw.id]: createDiscliamer(PaDisclaimerTemplate, [
      {
        title: 'Examples',
        content: [
          `What remedies are available under the Consumer Guarantees Act for faulty goods?`,
          `How does the Fair Trading Act regulate misleading advertising in New Zealand?`,
          `What are the legal rights of a consumer returning a defective product in NZ?`,
        ],
      },
    ]),
  },
}

export const nzTax = {
  namespaces: {
    ...createNamespaceObject(namespacesPinecone.nz.tax),
  },
  disclaimers: disclaimers.nz.tax,
  chatType: 'aiTax',
  practiceArea: 'tax',
  usesChatHistory: true,
}

export const nzTaxDepreciation = {
  namespaces: {
    'Tax Depreciation': {
      checked: true,
      namespace: 'nz_tax_depreciation_class_defaults',
    },
  },
  disclaimers: disclaimers.nz.depreciation,
  chatType: 'aiDRFinder',
  usesChatHistory: true,
}

export const general = {
  disclaimers: disclaimers.nz.general,
  chatType: 'aiGeneral',
  usesChatHistory: true,
}

export const docUpload = {
  disclaimers: disclaimers.nz.docUpload,
  chatType: 'aiDocUpload',
  usesChatHistory: true,
}

export const currentInfoModulesSet =
  process.env.REACT_APP_ENV === 'production'
    ? new Set([practiceAreaIds.TAX, practiceAreaIds.EMPLOYMENT])
    : new Set([
        practiceAreaIds.ADMINISTRATIVE_LAW,
        practiceAreaIds.ALTERNATIVE_DISPUTE_RESOLUTION,
        practiceAreaIds.BANKRUPTCY_AND_INSOLVENCY,
        practiceAreaIds.BUILDING_AND_CONSTRUCTION_LAW,
        practiceAreaIds.CHARITIES_LAW,
        practiceAreaIds.COMMERCIAL_AND_CONTRACT_LAW,
        practiceAreaIds.COMPANY_AND_FINANCIAL_MARKETS_LAW,
        practiceAreaIds.CONSUMER_RIGHTS_LAW,
        practiceAreaIds.COURT_PRACTICE_AND_PROCEDURE,
        practiceAreaIds.CRIMINAL_LAW,
        practiceAreaIds.EMPLOYMENT,
        practiceAreaIds.ENVIRONMENT_AND_RESOURCES,
        practiceAreaIds.EVIDENCE_LAW,
        practiceAreaIds.FAMILY_LAW,
        practiceAreaIds.FISHERIES_LAW,
        practiceAreaIds.HEALTH_LAW,
        practiceAreaIds.HUMAN_RIGHTS_AND_IMMIGRATION,
        practiceAreaIds.INSURANCE_LAW,
        practiceAreaIds.IP_AND_TECHNOLOGY_LAW_IN_NZ,
        practiceAreaIds.LEGAL_AID,
        practiceAreaIds.LOCAL_GOVERNMENT_LAW,
        practiceAreaIds.MAORI_LAW_RESEARCH_AREAS,
        practiceAreaIds.MEDIA_AND_PRIVACY_LAW,
        practiceAreaIds.PERSONAL_INJURY_AND_ACCIDENT_COMPENSATION,
        practiceAreaIds.PROPERTY_LAW,
        practiceAreaIds.SOCIAL_WELFARE_LAW,
        practiceAreaIds.TAX,
        practiceAreaIds.TRANSPORT_LAW,
        practiceAreaIds.TRUSTS_AND_EQUITY,
        practiceAreaIds.WILLS_AND_SUCCESSION,
        moduleIds.NZ_PRACTICE_AREA_BUNDLE,
      ])
