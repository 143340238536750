import * as RadixSlider from '@radix-ui/react-slider'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import React from 'react'
import Tippy from '@tippyjs/react'

export const Slider = ({ onValueCommit, defaultValue, max, min, step, hoverValue }) => (
  <RadixSlider.Root
    defaultValue={[defaultValue]}
    max={max}
    min={min}
    step={step}
    className="
      relative flex items-center 
      select-none touch-none
      w-[180px] h-[20px]
      py-4
    "
    onValueCommit={onValueCommit}
  >
    <RadixSlider.Track
      className="
        bg-[#94989f]
        relative 
        flex-grow
        rounded-full
        h-[4px]
      "
    >
      <RadixSlider.Range
        className="
          absolute 
          bg-[#12367f]
          rounded-full
          h-full
        "
      />
    </RadixSlider.Track>
    {/* LV RadixSlider.Thumb is duplicated here because I couldn't find a way to pull it out into a component and use it within tippy */}
    {hoverValue ? <Tippy
      content={hoverValue}
      arrow={roundArrow}
      animation="shift-away"
      inertia={true}
    >
      <RadixSlider.Thumb
        className="
        block 
        w-[20px] h-[20px] 
        bg-[#0c254d]
        rounded-[10px]
        shadow-[0_2px_10px_var(--black-a7)]
        focus:outline-none 
        focus:shadow-[0_0_0_5px_var(--black-a8)]
      "
      />
    </Tippy> : <RadixSlider.Thumb
      className="
        block 
        w-[20px] h-[20px] 
        bg-[#0c254d]
        rounded-[10px]
        shadow-[0_2px_10px_var(--black-a7)]
        focus:outline-none 
        focus:shadow-[0_0_0_5px_var(--black-a8)]
      "
    />}

  </RadixSlider.Root>
)