import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Portal,
} from '@radix-ui/react-dropdown-menu'
import { NavLink, useLocation } from 'react-router-dom'
import Style from './header.tailwind.js'
import styled from './header.module.scss'
import { DownIcon } from 'icons/down.icon.jsx'
import { useCallback, useContext } from 'react'
import { chatTypeIds, practiceAreaIds } from '@lawcyborg/packages'
import { useAccess } from 'hooks'
import { SettingsContext } from 'app/settings.js'
import { AuthContext, TagBadge } from 'components/lib.js'

export function Header(props) {
  const location = useLocation()
  const hasAccess = useAccess()
  const settingsContext = useContext(SettingsContext)
  const auth = useContext(AuthContext)
  const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_id))
  const availablePAs = Array.from(availableLicensesSet).filter((licence) =>
    Object.values(practiceAreaIds).includes(licence)
  ).length

  const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false

  const isOptionVisible = useCallback(
    (item) => {
      if (hasAccess(item.id)) return true
      if (item.id === chatTypeIds.AUTO) return availablePAs > 1 || (availablePAs === 1 && !shouldHideAiGeneral)
      if (item.id === chatTypeIds.GENERAL) return !shouldHideAiGeneral
      return false
    },
    [availablePAs, hasAccess, shouldHideAiGeneral]
  )

  const isActiveLink = (item) =>
    (location.pathname + location.search).includes(item.link) ||
    (location.pathname === '/' && item.link === '/ai/general')

  const visibleOptions = props.options?.filter(isOptionVisible)

  return (
    <header className={styled.header + ' [data-radix-popper-content-wrapper]:w-full ' + Style.header}>
      <div className={styled.left} />

      {visibleOptions?.length > 1 ? (
        <DropdownMenu>
          <DropdownMenuTrigger
            className="avatar-btn w-full"
            style={{
              minWidth: 'max-content',
            }}
          >
            {props.title && (
              <h1 className={Style.title}>
                {props.title}
                <span className="px-2 py-1 text-sm font-medium bg-[#0C254D20] rounded">
                  {visibleOptions.find(isActiveLink)?.label}
                </span>{' '}
                <DownIcon />
              </h1>
            )}
          </DropdownMenuTrigger>
          <Portal className="w-full">
            <DropdownMenuContent
              sideOffset={20}
              align={'center'}
              className={`bg-white p-2 min-w-40 font-normal text-sm border border-[#47556933] rounded-2xl max-h-[75vh] overflow-y-auto animate-fadeIn`}
            >
              {visibleOptions?.filter(isOptionVisible)?.map((item) => {
                if (item.link) {
                  return (
                    <DropdownMenuItem asChild key={item.label} className="focus-visible:outline-none">
                      <NavLink
                        className={
                          `flex gap-3 items-center p-2 ${item.hoverColor || 'hover:bg-[#0C254D10]'} mb-1 rounded relative` +
                          (isActiveLink(item)
                            ? item.activeBackgroundColor
                              ? ` ${item.activeBackgroundColor}`
                              : ' bg-[#0C254D15]'
                            : item.backgroundColor
                              ? ` ${item.backgroundColor}`
                              : '')
                        }
                        to={item.link}
                      >
                        {item.icon} {item.label} {item.id !== 28 && item.id !== 34 && item.id !== 37 && <TagBadge />}
                      </NavLink>
                    </DropdownMenuItem>
                  )
                }
                return null
              })}
            </DropdownMenuContent>
          </Portal>
        </DropdownMenu>
      ) : (
        props.title && (
          <h1 className={Style.title}>
            {/* LV At this point there is either 0 or 1 visible options, if the option has a label append it to the title */}
            {`${props.title}${visibleOptions?.[0]?.label ? ` ${visibleOptions[0].label}` : ''}`}
          </h1>
        )
      )}

      {props.children}
      <div className={styled.right} />
    </header>
  )
}
