import { Filter } from 'components/filter'
import SearchForm from './searchForm'
import useCallAPI from 'components/hooks/callApi'
import { useWindowSize } from 'hooks'
import { Link, useSearchParams } from 'react-router-dom'
import ResultItem from './individualResults'
import { Button, Pagination, Slider, ViewContext } from 'components/lib'
import { useContext, useEffect, useState } from 'react'
import { SearchInputContext } from './searchInputContext'
import { practiceAreas } from '@lawcyborg/packages'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { DebugContext } from 'app/debug'
import { useStopwatch } from 'hooks/useStopwatch.hook'

const DEBUG_MIN_RESULT_AMOUNT = 1
const DEBUG_MAX_RESULT_AMOUNT = 400
const DEBUG_DEFAULT_RESULT_AMOUNT = 50

const DebugTools = ({ onResultAmountCommit, serverQueryTime, elapsedTime }) => {
  const [resultsReturned, setResultsReturned] = useState(DEBUG_DEFAULT_RESULT_AMOUNT)

  return (
    <div className="flex flex-col">
      <h2 className="uppercase font-semibold text-sm text-primary-dark m-1 py-2">Result Amount</h2>
      <div className="flex">
        <p className="mr-3 mt-1">{DEBUG_MIN_RESULT_AMOUNT}</p>
        <Slider
          onValueCommit={(e) => {
            onResultAmountCommit(e[0])
            setResultsReturned(e[0])
          }}
          max={DEBUG_MAX_RESULT_AMOUNT}
          min={DEBUG_MIN_RESULT_AMOUNT}
          step={1}
          defaultValue={DEBUG_DEFAULT_RESULT_AMOUNT}
          hoverValue={resultsReturned}
        />
        <p className="ml-3 mt-1">{DEBUG_MAX_RESULT_AMOUNT}</p>
        <Tippy
          content={`Adjust how many documents are returned in the search results.`}
          arrow={roundArrow}
          animation="shift-away"
          inertia={true}
        >
          <div className="px-3">
            <Button
              icon={'alert-circle'}
              color={'dark'}
              size={20}
              className={
                'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
              }
            />
          </div>
        </Tippy>
        <div>
          <p>{`Server Query Time (s): ${serverQueryTime}`}</p>
          <p>{`Elapsed Time (s): ${elapsedTime / 1000}`}</p>
        </div>
      </div>
    </div>
  )
}

const SearchResults = () => {
  const viewContext = useContext(ViewContext)
  const debugContext = useContext(DebugContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { searchInputValue, setSearchInputValue } = useContext(SearchInputContext)
  const fromToolPage = searchParams.get('fromToolPage') === 'true'
  const searchKey = searchParams.get('searchKey') || ''

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [paginatedResults, setPaginatedResults] = useState([])

  const isNoPracticeArea =
    getActiveNamespaces(
      JSON.parse(localStorage.getItem('namespaceMap') || '{}'),
      JSON.parse(searchParams.get('nSpaces') || '{}')['Document Types']
    ).length === 0

  const { width: windowWidth } = useWindowSize()
  const [search, results, loading, error] = useCallAPI({
    url: '/api/search',
    method: 'POST',
  })

  const [relevantVsRecentValue, setRelevantVsRecentValue] = useState(0)
  const [resultsReturned, setResultsReturned] = useState(undefined)
  const [serverQueryTime, setServerQueryTime] = useState(0)
  const { elapsedTime, start, stop, reset } = useStopwatch()

  // Calculate pagination
  useEffect(() => {
    if (results?.data?.results) {
      const startIndex = (currentPage - 1) * rowsPerPage
      const endIndex = startIndex + rowsPerPage
      setPaginatedResults(results.data.results.slice(startIndex, endIndex))
    }
  }, [currentPage, rowsPerPage, results])

  const totalPages = results?.data?.results ? Math.ceil(results.data.results.length / rowsPerPage) : 1

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setCurrentPage(1)
  }
  useEffect(() => {
    if (results?.data) stop()
    if (results?.data?.queryTime) {
      setServerQueryTime(results.data.queryTime)
    }
  }, [results])

  useEffect(() => {
    if (debugContext.debug) {
      setServerQueryTime(0)
      setResultsReturned(DEBUG_DEFAULT_RESULT_AMOUNT)
      reset()
    } else {
      setResultsReturned(undefined)
    }
  }, [debugContext.debug])

  function transformObject(inputObj) {
    if (inputObj == null) {
      return {}
    }

    return Object.fromEntries(
      Object.entries(inputObj)
        .map(([category, subCategories]) => [
          category,
          Object.keys(subCategories || {}).filter((key) => subCategories[key]),
        ])
        .filter(([, trueKeys]) => trueKeys.length)
    )
  }

  function getActiveNamespaces(obj1, obj2, path = '') {
    const namespaces = []

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key

        if (typeof obj2[key] === 'object' && obj2[key] !== null) {
          const nestedNamespaces = getActiveNamespaces(obj1[key] || {}, obj2[key], newPath)
          namespaces.push(...nestedNamespaces)
        } else if (obj1[key]) {
          namespaces.push(obj1[key])
        }
      }
    }

    return namespaces
  }

  const handleSubmit = async () => {
    setSearchParams((prev) => {
      prev.set('searchKey', searchInputValue)
      return prev
    })

    if (searchInputValue === '') {
      viewContext.notification.show('Please enter a search term', 'error')
      return
    }

    if (searchInputValue !== '') {
      let namespaces = getActiveNamespaces(
        JSON.parse(localStorage.getItem('namespaceMap') || '{}'),
        JSON.parse(searchParams.get('nSpaces') || '{}')['Document Types']
      )

      if (debugContext.debug) {
        reset()
        start()
        setServerQueryTime(0)
      }

      search({
        requestData: {
          query: searchInputValue,
          sortBy: searchParams.get('sortBy') || 'Most Recent',
          filters: {
            practice_area: Object.values(practiceAreas).find((pa) => pa.slug === searchParams.get('pArea'))?.id,
            namespaces,
            categories: transformObject(JSON.parse(searchParams.get('filters'))?.category),
          },
          relevantVsRecent: relevantVsRecentValue,
          searchMode: searchParams.get('strictSearch') === 'true' ? 'strict' : 'smart',
          auto: fromToolPage || !namespaces.length,
          resultsReturned,
        },
      })
      setCurrentPage(1)

      if (fromToolPage) {
        setSearchParams((prev) => {
          prev.delete('fromToolPage')
          return prev
        })
      }
    }
  }

  useEffect(() => {
    if (searchKey && fromToolPage === true) {
      handleSubmit(searchKey)
      setSearchInputValue(searchKey)
    }
  }, [searchKey])

  return (
    <div className="flex !pt-4 gap-2 h-full overflow-y-auto justify-between">
      {windowWidth > 1280 ? (
        <div className="logoMargin text-xl my-5 mx-8 font-bold">
          <Link className="text-primary-dark" to="/search">
            <img
              className="text-primary-dark h-14"
              src="https://ominous.nz/storage/Cropped-Seafarer-Logo.svg"
              alt="Law Cyborg Search Logo"
            />
          </Link>
        </div>
      ) : null}
      <div className="left flex-1">
        {debugContext.debug ? (
          <DebugTools
            onResultAmountCommit={(val) => setResultsReturned(val)}
            serverQueryTime={serverQueryTime}
            elapsedTime={elapsedTime}
          />
        ) : null}
        <div className="results mx-4">
          <div className="w-full">
            <SearchForm handleSubmit={handleSubmit} searchParams={searchParams} className="bg-white" />
          </div>
          <div className="results px-2 my-4 max-w-[800px] mx-auto">
            {loading ? (
              <p className="ml-4">Loading...</p>
            ) : error ? (
              <p className="text-red-500">An error occurred while fetching results.</p>
            ) : (
              results?.data?.results?.length > 0 && (
                <>
                  <span className="resultSummary text-sm text-[#475569] ml-4">
                    {`Showing ${(currentPage - 1) * rowsPerPage + 1} to ${Math.min(
                      currentPage * rowsPerPage,
                      results?.data?.results?.length
                    )} of ${results.data.results.length} results for "${searchKey}"`}
                  </span>

                  {paginatedResults.map((result, index) => (
                    <div key={index} className="border-b py-2">
                      <ResultItem id={index + (currentPage - 1) * rowsPerPage} {...result} highlight={searchKey} />
                    </div>
                  ))}

                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    visiblePages={5}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    currentRowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    className="custom-pagination py-4"
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
      <div className="right my-4 ml-8 min-w-64 max-w-72">
        <div className="flex w-full pb-5 border-b-[1px]">
          <h2 className="inline font-bold text-xl text-[#0C254D]">Filter</h2>
        </div>
        <div className="items-center gap-4 mt-8 mb-4 px-2">
          <h2 className="uppercase font-semibold text-sm text-primary-dark m-1 py-2">Recency</h2>
          <div className="flex">
            <Slider
              onValueCommit={(e) => setRelevantVsRecentValue(e[0])}
              max={1}
              min={0}
              step={0.01}
              defaultValue={0}
            />
            <Tippy
              touch={false}
              content={`Adjust how much recency is taken into account for a given search. When the slider is furthest to the left, recency will have little influence on the ordering of results.`}
              arrow={roundArrow}
              animation="shift-away"
              inertia={true}
            >
              <div className="px-3">
                <Button
                  icon={'alert-circle'}
                  color={'dark'}
                  size={20}
                  className={
                    'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
                  }
                />
              </div>
            </Tippy>
          </div>
        </div>
        <Filter />
        {isNoPracticeArea && (
          <Tippy
            touch={false}
            content={`Search works best with a Practice Area selected.`}
            arrow={roundArrow}
            animation="shift-away"
            inertia={true}
          >
            <div className="absolute right-4 bottom-4">
              <Button
                icon={'alert-circle'}
                color={'dark'}
                size={20}
                className={
                  'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
                }
              />
            </div>
          </Tippy>
        )}
      </div>
    </div>
  )
}

export default SearchResults
