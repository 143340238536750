import { chatTypeIds, practiceAreaIds, practiceAreas } from '@lawcyborg/packages'
import { SettingsContext } from 'app/settings'
import { PromptSubmitted } from 'components/event'
import useCallAPI from 'components/hooks/callApi'
import { AuthContext, ViewContext, WebSocketContext } from 'components/lib'
import { useAccess } from 'hooks'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
const { v4: uuidv4 } = require('uuid')

export const useConversation = ({
  chatType: chatTypeParam,
  practiceArea: practiceAreaParam,
  usesChatHistory: usesChatHistoryParam = false,
}) => {
  const { send, close, socket } = useContext(WebSocketContext)
  const viewContext = useContext(ViewContext)
  const auth = useContext(AuthContext)
  const hasAccess = useAccess()
  const [activeExchanges, setActiveExchanges] = useState([])
  const [inputMessage, setInputMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [canSend, setCanSend] = useState(true)
  const [usedSuggestion, setUsedSuggestion] = useState(false)
  const [mode, setMode] = useState('Default')
  const [dropdownValue, setDropdownValue] = useState('')
  const [fileID, setFileID] = useState('')
  const [messageQueued, setMessageQueued] = useState(null)
  const settingsContext = useContext(SettingsContext)
  const [conversation, setConversation] = useState(null)
  const [unsavedExchanges, setUnsavedExchanges] = useState([])
  const [requiresUpdateExchanges, setRequiresUpdateExchanges] = useState([])
  const [conversationName, setConversationName] = useState('')
  const [historyCached, setHistoryCached] = useState(false)
  const [selectedNamespaces, setSelectedNamespaces] = useState([])

  const [conversationId, setConversationId] = useState(null)

  const [chatType, setChatType] = useState(chatTypeParam)
  const [practiceArea, setPracticeArea] = useState(practiceAreaParam)
  const [usesChatHistory, setUsesChatHistory] = useState(usesChatHistoryParam)

  const [auto, setAuto] = useState(true)

  const [createConversation] = useCallAPI({
    method: 'POST',
    url: '/api/conversation',
  })

  const [fetchConversations, conversations] = useCallAPI({
    method: 'GET',
    url: '/api/conversations',
    params: useMemo(() => ({ type: chatType }), [chatType]),
  })

  const [fetchConversation, , loadingConversation] = useCallAPI({
    method: 'GET',
    url: '/api/conversation',
  })

  const [createExchanges] = useCallAPI({
    method: 'POST',
    url: '/api/exchange',
  })

  const [updateExchanges] = useCallAPI({
    method: 'POST',
    url: '/api/exchange/update',
  })

  const setConversationConfig = useCallback(({ chatType, practiceArea, usesChatHistory }) => {
    setChatType(chatType)
    setPracticeArea(practiceArea)
    setUsesChatHistory(usesChatHistory)
  }, [])

  const createNewExchange = useCallback((message, parentID) => {
    return {
      id: uuidv4(),
      parentID,
      childIDs: [],
      user: {
        message,
      },
      assistant: {
        response: '',
      },
    }
  }, [])

  const getExchange = useCallback(
    (exchangeID, conv = conversation) => {
      return conv.find((exchange) => exchange.id === exchangeID)
    },
    [conversation]
  )

  const getParentExchange = useCallback(
    (exchangeID, conv = conversation) => {
      const exchange = getExchange(exchangeID, conv)
      return getExchange(exchange.parentID, conv)
    },
    [getExchange, conversation]
  )

  const getRootExchange = useCallback(
    (conv = conversation) => {
      return conv.find((exchange) => exchange.parentID === null)
    },
    [conversation]
  )

  const collectActiveExchanges = useCallback(
    (
      conv = conversation,
      currentExchange = getExchange(getRootExchange(conv)?.activeChildID, conv),
      activeExchanges = []
    ) => {
      if (!currentExchange) {
        return activeExchanges
      }

      const parentExchange = getExchange(currentExchange.parentID, conv)

      currentExchange.siblingCount = parentExchange.childIDs.length
      currentExchange.siblingIndex = parentExchange.childIDs.indexOf(currentExchange.id)

      activeExchanges.push(currentExchange)

      const nextExchange = getExchange(currentExchange.activeChildID, conv)

      if (!nextExchange) {
        return activeExchanges
      }

      return collectActiveExchanges(conv, nextExchange, activeExchanges)
    },
    [getExchange, getRootExchange, conversation]
  )

  const branch = useCallback(
    async (sourceExchangeID, message) => {
      const parentExchange = getParentExchange(sourceExchangeID)
      const newExchange = createNewExchange(message, parentExchange.id)
      parentExchange.childIDs.push(newExchange.id)
      parentExchange.activeChildID = newExchange.id

      const newConversation = [...conversation, newExchange]
      setConversation(newConversation)

      if (!unsavedExchanges.includes(parentExchange.id)) {
        setRequiresUpdateExchanges((prev) => [...prev, parentExchange.id])
      }

      setUnsavedExchanges((prev) => [...prev, newExchange.id])

      close()
      setActiveExchanges(collectActiveExchanges(newConversation))
      setMessageQueued(true)
    },
    [conversation, close, unsavedExchanges, collectActiveExchanges, createNewExchange, getParentExchange]
  )

  const swapBranch = useCallback(
    async (sourceExchangeID, direction) => {
      const parentExchange = getParentExchange(sourceExchangeID)

      const activeIndex = parentExchange.childIDs.indexOf(parentExchange.activeChildID)
      const newIndex = activeIndex + direction
      parentExchange.activeChildID = parentExchange.childIDs[newIndex]

      const exchanges = collectActiveExchanges()

      close()
      setCanSend(true)
      setActiveExchanges(exchanges)
    },
    [getParentExchange, collectActiveExchanges, close]
  )

  // LV Save new exchanges to the database.
  const saveExchanges = useCallback(
    async (exchangeIDs) => {
      if (exchangeIDs.length === 0) return

      const exchangesToSave = conversation.filter((exchange) => exchangeIDs.includes(exchange.id))

      let response
      if (conversationId !== null) {
        response = await createExchanges({
          requestData: {
            conversationId,
            exchanges: exchangesToSave,
          },
        })
        if (response.success) {
          setUnsavedExchanges([])
        }
      } else {
        response = await createConversation({
          requestData: {
            name: activeExchanges[0].user.message.slice(0, 40),
            exchanges: exchangesToSave,
            type: chatType,
          },
        })
        if (response?.id) {
          fetchConversations()
          setConversationId(response.id)
          setUnsavedExchanges([])
        }
      }
    },
    [conversationId, createExchanges, activeExchanges, createConversation, chatType, fetchConversations, conversation]
  )

  // LV Update the exchanges in the database.
  const doUpdateExchanges = useCallback(
    async (exchangeIDs) => {
      if (exchangeIDs.length === 0) return

      const exchangesToUpdate = conversation.filter((exchange) => exchangeIDs.includes(exchange.id))

      const response = await updateExchanges({
        requestData: {
          exchanges: exchangesToUpdate,
        },
      })

      if (response.success) {
        setRequiresUpdateExchanges([])
      }
    },
    [updateExchanges, conversation]
  )

  const setErrorResponse = useCallback(() => {
    const errorMsg = 'An error occured'
    setActiveExchanges((exchanges) => {
      const pendingExchange = exchanges[exchanges.length - 1]
      if (pendingExchange.assistant.response) {
        pendingExchange.assistant.response += '\n\n' + errorMsg
        return [...exchanges.slice(0, -1), pendingExchange]
      } else {
        pendingExchange.assistant.response = errorMsg
        return [...exchanges.slice(0, -1), pendingExchange]
      }
    })
  }, [])

  const handleAIResponseStreaming = useCallback((token) => {
    setLoading(false)
    setCanSend(false)
    setActiveExchanges((exchanges) => {
      const pendingExchange = exchanges[exchanges.length - 1]
      pendingExchange.assistant.response += token
      return [...exchanges.slice(0, -1), pendingExchange]
    })
  }, [])

  const handleAIResponseChatTypeId = useCallback((id) => {
    setActiveExchanges((exchanges) => {
      const pendingExchange = exchanges[exchanges.length - 1]
      pendingExchange.assistant.chatTypeId = id
      return [...exchanges.slice(0, -1), pendingExchange]
    })
  }, [])

  const handleAIResponseFinal = useCallback(
    async (data) => {
      const pendingExchange = activeExchanges[activeExchanges.length - 1]
      pendingExchange.assistant = {
        ...pendingExchange.assistant,
        accuracy: data.accuracy ? parseFloat(data.accuracy.replace('%', '')) : null,
        sources: data.sources,
        seeAlsos: data.seeAlsos,
        citations: data.citations,
        embedMessages: data.embedMessages,
      }

      if (settingsContext?.isPermitted('chat_history') && usesChatHistory) {
        setCanSend(false)
        await saveExchanges(unsavedExchanges)
        await doUpdateExchanges(requiresUpdateExchanges)
      }

      // LV Exchange references don't change here, we just want
      // the containing array to update so that the relevant
      // components can re-render.
      setActiveExchanges((prev) => [...prev.slice(0, -1), pendingExchange])

      setLoading(false)
      setCanSend(true)
    },
    [
      settingsContext,
      usesChatHistory,
      activeExchanges,
      saveExchanges,
      unsavedExchanges,
      doUpdateExchanges,
      requiresUpdateExchanges,
    ]
  )

  const handleAiResponse = useCallback(
    (chatData) => {
      let data = JSON.parse(chatData.data)
      if (data.responseType === 'error') {
        viewContext.notification.show(data.notification, 'error')
        console.error('Error: ', data.message)
        setErrorResponse()
      } else if (data.responseType === 'final') {
        handleAIResponseFinal(data)
      } else if (data.responseType === 'streaming') {
        handleAIResponseStreaming(data.token)
      } else if (data.responseType === 'chatTypeId') {
        handleAIResponseChatTypeId(data.chatTypeId)
      }
    },
    [
      handleAIResponseFinal,
      handleAIResponseStreaming,
      handleAIResponseChatTypeId,
      viewContext.notification,
      setErrorResponse,
    ]
  )

  const handleAiError = useCallback(
    (error) => {
      viewContext.notification.show('Failed to send message', 'error', true)
      setErrorResponse()
      console.error('Socket Error', error)
      setLoading(false)
      setCanSend(true)
    },
    [viewContext.notification, setErrorResponse]
  )

  const handleAiClose = useCallback((event) => {
    console.log('Socket closed', event.reason)
    setLoading(false)
  }, [])

  const sendMessageToServer = useCallback(async () => {
    try {
      send(
        {
          action: practiceArea === chatTypeIds.AUTO ? 'aiAuto' : chatType, // LV We manually do this because our chat type system is odd. We store aiTaxNew for every practice in the db records, and in order for everything to work we need to do this for auto too. However we also use chatType on the websocket to determine our handler. But the handler is getting messy and I want to use a different one for auto. Therefore I've need to manually set the action here. TODO: Come up with a better system both for this and the db records.
          apiVersion: 'v2',
          practiceArea,
          exchanges: activeExchanges,
          namespaces: auto ? ['auto'] : selectedNamespaces,
          dropdownValue: dropdownValue.value,
          mode,
          fileID,
          token: auth?.user?.token,
        },
        handleAiResponse,
        handleAiError,
        handleAiClose
      )
      setLoading(true)
      setCanSend(false)
    } catch (error) {
      setErrorResponse()
      viewContext.notification.show('Failed to send message', 'error')
    } finally {
      setMessageQueued(false)
    }

    try {
      const practiceAreaSlug = Object.values(practiceAreas).find((x) => x.id === practiceArea)?.slug || null
      PromptSubmitted(activeExchanges[activeExchanges.length - 1].user.message, {
        conversation_length: activeExchanges.length / 2 + 1,
        endpoint: chatType,
        practiceArea: practiceAreaSlug,
      })
    } catch (error) {
      console.error('Failed to send prompt submitted event to server:', error)
    }
  }, [
    send,
    handleAiError,
    handleAiResponse,
    chatType,
    activeExchanges,
    dropdownValue,
    mode,
    fileID,
    handleAiClose,
    setErrorResponse,
    viewContext.notification,
    practiceArea,
    auto,
    selectedNamespaces,
    auth?.user?.token,
  ])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (inputMessage === '') {
        return
      }

      const DEFAULT_ALLOWED_CHAT_TYPES = [chatTypeIds.GENERAL, chatTypeIds.DOCUMENT_UPLOAD, chatTypeIds.DEPRECIATION]

      const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_id))
      const hasOnePracticeArea = Array.from(availableLicensesSet).some((licence) =>
        Object.values(practiceAreaIds).includes(licence)
      )

      if (hasOnePracticeArea) DEFAULT_ALLOWED_CHAT_TYPES.push(chatTypeIds.AUTO)

      if (!hasAccess(practiceArea) && !DEFAULT_ALLOWED_CHAT_TYPES.includes(practiceArea)) {
        viewContext.notification.show('You do not have access to this information module.', 'error')
        return
      }

      if (selectedNamespaces && selectedNamespaces.length === 0 && !auto) {
        viewContext.notification.show('Please select at least one document type to search (or select Auto).', 'error')
        return
      }

      let rootExchange = null
      let parentID = null

      if (conversation === null || conversation.length === 0) {
        rootExchange = createNewExchange('', null)
        parentID = rootExchange.id
      } else {
        parentID = activeExchanges[activeExchanges.length - 1].id
      }

      const newExchange = createNewExchange(inputMessage, parentID)

      let parentExchange =
        conversation === null || conversation.length === 0 ? rootExchange : getExchange(newExchange.parentID)

      parentExchange.childIDs.push(newExchange.id)
      parentExchange.activeChildID = newExchange.id

      newExchange.siblingCount = parentExchange.childIDs.length - 1
      newExchange.siblingIndex = newExchange.siblingCount

      setConversation((prev) => [
        ...(prev || []),
        ...(conversation === null || conversation.length === 0 ? [rootExchange] : []),
        newExchange,
      ])

      const unsavedIDs = [newExchange.id]

      // LV If the parent exchange is not already in the unsaved list,
      // then it must have already been saved and we need to update it.
      if (!unsavedExchanges.includes(parentExchange.id)) {
        // LV If the message is the first message in a new conversation
        // save the root exchange as well.
        if (parentExchange.parentID === null) {
          unsavedIDs.push(parentExchange.id)
        } else {
          setRequiresUpdateExchanges((prev) => [...prev, parentExchange.id])
        }
      }

      setUnsavedExchanges((prev) => [...prev, ...unsavedIDs])
      setActiveExchanges((prev) => [...prev, newExchange])
      setInputMessage('')
      setCanSend(true)
      setMessageQueued(true)
    },
    [
      inputMessage,
      conversation,
      activeExchanges,
      getExchange,
      unsavedExchanges,
      setRequiresUpdateExchanges,
      createNewExchange,
      auto,
      selectedNamespaces,
      practiceArea,
    ]
  )
  const loadConversations = useCallback(async () => {
    if (settingsContext?.isPermitted('chat_history') && usesChatHistory && !historyCached) {
      await fetchConversations()
      setHistoryCached(true)
    }
  }, [settingsContext, fetchConversations, usesChatHistory, historyCached])

  const loadConversation = useCallback(
    async (conversationId) => {
      const response = await fetchConversation({
        params: { conversationId },
      })
      if (response?.data) {
        const conversation = response.data
        setConversation(conversation)
        setConversationId(conversationId)
        setUnsavedExchanges([])
        setRequiresUpdateExchanges([])
        close()
        setCanSend(true)
        setActiveExchanges(collectActiveExchanges(conversation))
      }
    },
    [fetchConversation, close, collectActiveExchanges]
  )

  const stopStreaming = useCallback(async () => {
    close()
    setCanSend(true)
    setLoading(false)

    const pendingExchange = activeExchanges[activeExchanges.length - 1]
    pendingExchange.assistant.response += ' [Streaming stopped]'

    if (settingsContext?.isPermitted('chat_history') && usesChatHistory) {
      await saveExchanges(unsavedExchanges)
      await doUpdateExchanges(requiresUpdateExchanges)
    }
  }, [
    close,
    saveExchanges,
    unsavedExchanges,
    doUpdateExchanges,
    requiresUpdateExchanges,
    activeExchanges,
    settingsContext,
    usesChatHistory,
  ])

  const doCreateConversation = useCallback(
    async (name, type, fileID = null) => {
      if (settingsContext?.isPermitted('chat_history') && usesChatHistory) {
        const response = await createConversation({
          requestData: {
            name,
            type,
            fileID,
          },
        })

        if (response?.id) {
          fetchConversations()
          setConversationId(response.id)
        }
      }
    },
    [createConversation, fetchConversations, settingsContext, usesChatHistory]
  )

  useEffect(() => {
    if (messageQueued) {
      sendMessageToServer()
      setMessageQueued(false)
    }
  }, [messageQueued, sendMessageToServer])

  useEffect(() => {
    // LV We need to call close on the socket directly here as
    // when this cleanup function is run, if we use the close() function
    // from the context, by then the socket would have been set to null.
    // Therefore we capture the value of the socket in the closure so that
    // when the component unmounts or the dependancy changes, we can call
    // close on the old socket.
    return () => {
      if (socket) {
        socket.close()
      }
    }
  }, [socket])

  return {
    conversations,
    conversationId,
    setConversationId,
    exchanges: activeExchanges,
    setExchanges: setActiveExchanges,
    inputMessage,
    setInputMessage,
    errorMessage,
    setErrorMessage,
    loading,
    setLoading,
    canSend,
    setCanSend,
    usedSuggestion,
    setUsedSuggestion,
    handleSubmit,
    dropdownValue,
    setDropdownValue,
    mode,
    setMode,
    fileID,
    setFileID,
    conversationName,
    setConversationName,
    doCreateConversation,
    branch,
    swapBranch,
    loadConversation,
    loadingConversation,
    stopStreaming,
    loadConversations,
    historyCached,
    setConversationConfig,
    setSelectedNamespaces,
    selectedNamespaces,
    auto,
    setAuto,
    setChatType,
    setPracticeArea,
    practiceArea,
  }
}
