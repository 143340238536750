import { moduleIds } from '@lawcyborg/packages'
import MultiSelect from 'components/form/multiSelect/multiSelect'
import Checkbox from 'components/form/newCheckbox'
import useCallAPI from 'components/hooks/callApi'
import {
  AuthContext,
  Button,
  Drawer,
  Event,
  InlineEditableField,
  TagBadge,
  useAPI,
  usePermissions,
  ViewContext,
} from 'components/lib'
import { ProfileIcon } from 'icons'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const UserProfile = ({ userId }) => {
  const permissions = usePermissions()
  const permissionOptions = permissions?.data?.list?.filter((x) => x.value !== 'owner' && x.value !== 'developer')
  const [callGetUser, userResponse, loading] = useCallAPI({
    url: `/api/user/${userId}`,
  })
  const [callUpdateUser] = useCallAPI({
    url: `/api/user`,
    method: 'PATCH',
  })

  useEffect(() => {
    callGetUser()
  }, [])
  const user = userResponse?.data

  const handleUserDetailsSave = ({ email = user?.email, name = user?.name, permission = user?.permission }) => {
    callUpdateUser({
      requestData: {
        id: userId,
        email,
        name,
        permission,
      },
      onSuccess: (res) => {
        if (user?.permission !== 'admin' && res.data?.permission === 'admin') {
          Event('admin_role_assigned', {
            user_id: user.id,
            existingRole: user.permission,
            newRole: res.data.permission,
          })
        }
        callGetUser()
      },
    })
  }
  // if (loading) return <>loading...</>
  return (
    <div className="flex items-center gap-4 mb-4">
      <ProfileIcon className="mb-auto" />
      <div className="flex-1">
        <InlineEditableField
          value={user?.name}
          onSave={(name) => handleUserDetailsSave({ name })}
          placeholder="Name"
          inputClassName="text-2xl font-semibold text-primary-dark-blue"
          className="font-semibold text-2xl text-primary-dark-blue px-1 w-full"
        />
        <InlineEditableField
          value={user?.email}
          onSave={(email) => handleUserDetailsSave({ email })}
          placeholder="Email"
          inputClassName="text-sm font-medium"
          className="text-sm font-medium my-1 py-0.5 px-1"
        />
        {user?.permission === 'owner' ? (
          <p className="text-sm font-medium">{user.permission}</p>
        ) : (
          <p>
            <select
              className="border-b-2 px-1 focus:outline-none text-sm font-medium"
              value={user?.permission}
              onChange={(e) => handleUserDetailsSave({ permission: e.target.value })}
              autoFocus
            >
              {permissionOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </p>
        )}
        <div className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 mt-5 uppercase rounded">
          {user?.verified ? 'Verified' : 'Registered'}
        </div>
      </div>
    </div>
  )
}

const DrawerContent = ({ onOpenChange, plan }) => {
  const context = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('userId')
  const [assignProduct, _, submitLoading] = useCallAPI({
    url: '/api/assign-product',
    method: 'POST',
  })
  const [assignPlan, , submitPlanLoading] = useCallAPI({
    url: `/api/update-plan/${userId}`,
    method: 'PUT',
  })
  const [getAssignProducts, assignedLicense] = useCallAPI({ url: `api/user/licenses/${userId}` })
  const initialAssignedIds = new Set(assignedLicense?.data?.map((a) => a.account_plans_id))
  const initialAssignedModuleIds = new Set(assignedLicense?.data?.map((a) => a.module_id))
  const productResponse = useAPI('/api/assigned-product-list')
  const [products, setProducts] = useState(productResponse.data || [])
  const planOptions = plan?.map((plan) => ({ label: plan.name, value: plan.module_id }))
  const [selectedLicenses, setSelectedLicenses] = useState({})
  const [selectedPlan, setSelectedPlan] = useState([])

  useEffect(() => {
    getAssignProducts()
  }, [])

  useEffect(() => {
    const initialSelectedLicenses = productResponse?.data?.reduce((acc, product) => {
      acc[product.id] = initialAssignedIds.has(product.id)
      return acc
    }, {})
    const initialSelectedPlan =
      planOptions?.filter((plan) => initialAssignedModuleIds.has(plan.value)).map((plan) => plan.value) ?? []
    setSelectedPlan(initialSelectedPlan)
    setSelectedLicenses(initialSelectedLicenses)
  }, [productResponse, assignedLicense])

  useEffect(() => {
    const planSet = new Set([moduleIds.ESSENTIAL_PLAN, moduleIds.ELITE_PLAN])
    const tempProductResponse =
      productResponse.data?.filter((indivdualProduct) => !planSet.has(indivdualProduct.module_id)) || []
    setProducts(tempProductResponse)
  }, [productResponse])

  const handleLicenseToggle = (productId) => {
    setSelectedLicenses((prev) => {
      const newState = { ...prev }
      newState[productId] = !newState[productId]
      return newState
    })

    setProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.id === productId) {
          const isNowSelected = !selectedLicenses[productId]
          return {
            ...product,
            assigned_license: isNowSelected ? product.assigned_license + 1 : product.assigned_license - 1,
          }
        }
        return product
      })
    })
  }

  const handleSave = async () => {
    const assignedPriceIds = []
    const unassignedPriceIds = []
    const initialSelectedPlan =
      planOptions?.filter((plan) => initialAssignedModuleIds.has(plan.value)).map((plan) => plan.value) ?? []

    for (const [id, isSelected] of Object.entries(selectedLicenses)) {
      const wasAssigned = initialAssignedIds.has(id)
      if (isSelected && !wasAssigned) {
        assignedPriceIds.push(id)
      } else if (!isSelected && wasAssigned) {
        unassignedPriceIds.push(id)
      }
    }

    let assignPlanResponse = null
    let assignProductResponse = null

    try {
      const waitForConfirmation = () =>
        new Promise((resolve) => {
          context.lcModal.show(
            {
              title: 'Are you sure?',
              form: {},
              buttonText: 'Yes Please',
              text: 'Upgrading to an Elite Plan (while a fantastic idea) will increase your monthly subscription cost. Are you sure that you would like to do this?',
            },
            () => resolve(true),
            () => resolve(false)
          )
        })

      if (initialSelectedPlan[0] !== selectedPlan[0]) {
        if (selectedPlan[0] === moduleIds.ELITE_PLAN) {
          const confirmed = await waitForConfirmation()
          if (!confirmed) return

          assignPlanResponse = await assignPlan({
            requestData: {
              plan: plan?.find((individualPlan) => individualPlan.module_id === selectedPlan[0])?.price_id ?? '',
            },
          })
          context.lcModal.hide()
        } else {
          assignPlanResponse = await assignPlan({
            requestData: {
              plan: plan?.find((individualPlan) => individualPlan.module_id === selectedPlan[0])?.price_id ?? '',
            },
          })
        }
      }
      if ((assignedPriceIds.length > 0 || unassignedPriceIds.length > 0) && selectedPlan?.length > 0) {
        assignProductResponse = await assignProduct({
          requestData: {
            assignedTo: userId,
            assignedPriceIds,
            unassignedPriceIds,
          },
        })
      }

      if (
        (!assignPlanResponse || (assignPlanResponse && !assignPlanResponse.isAxiosError)) &&
        (!assignProductResponse || (assignProductResponse && !assignProductResponse.isAxiosError))
      ) {
        context.notification.show('Licenses were assigned', 'success', true)
        onOpenChange(false)
        authContext.refreshAuth()
      } else {
        const errorMessage =
          assignPlanResponse?.message || assignProductResponse?.message || 'An error occurred during the assignment.'
        context.notification.show(errorMessage, 'error', true)
      }
    } catch (error) {
      context.notification.show('An unexpected error occurred', 'error', true)
    }
  }

  const hasChanges = useCallback(() => {
    const initialSelectedPlan =
      planOptions?.filter((plan) => initialAssignedModuleIds.has(plan.value)).map((plan) => plan.value) ?? []
    if (initialSelectedPlan[0] !== selectedPlan[0]) {
      return true
    }
    for (let id in selectedLicenses) {
      const isSelected = selectedLicenses[id]
      const wasAssigned = initialAssignedIds.has(id)
      if (isSelected !== wasAssigned) {
        return true
      }
    }
    return false
  }, [selectedLicenses, initialAssignedIds])

  return (
    <>
      <UserProfile userId={userId} />
      {productResponse?.loading ? (
        <div className="flex justify-center items-center h-36">loading...</div>
      ) : products && products?.length <= 0 ? (
        <div className="flex justify-center flex-col gap-2 items-center h-36">
          <h2>No data found </h2>
          <Button small text="Purchase Licence" goto={'/account/products'} color={'brand3'} />
        </div>
      ) : (
        <>
          <div className="h-[calc(100dvh-20rem)] overflow-y-auto">
            <h4 className="font-semibold text-gray-800 mt-4 mb-2">Plan</h4>
            <MultiSelect
              singleSelect
              options={planOptions}
              className="flex-1"
              id="plan-dropdown"
              selectedValues={selectedPlan}
              onValueChange={(value) => setSelectedPlan(value)}
              placeholder="Select plan"
            />
            {selectedPlan.length > 0 && (
              <>
                <h4 className="font-semibold text-gray-800 mt-4 mb-2">Practice Area Licences</h4>
                {products?.map((product, id) => (
                  <div key={product.price_id + id} className="flex items-center justify-between">
                    <div className="flex-1">
                      <Checkbox
                        item={{
                          value: product.price_id,
                          label: (
                            <>
                              {product.name} <TagBadge />
                            </>
                          ),
                          checked: selectedLicenses[product.id] || false,
                          disabled: selectedLicenses[product.id]
                            ? false
                            : product.purchased_quantity - product.assigned_license <= 0,
                        }}
                        onChange={() => handleLicenseToggle(product.id)}
                      />
                    </div>
                    <span className="text-sm text-gray-500">
                      {product.purchased_quantity - product.assigned_license} of {product.purchased_quantity} available
                    </span>
                  </div>
                ))}
              </>
            )}

            <br />
            <p class="italic text-gray-700">
              Purchase additional licences{' '}
              <span class="underline not-italic">
                <a href="/account/products">here</a>
              </span>
            </p>
          </div>
          <Button
            disabled={!hasChanges()}
            small
            loading={submitLoading || submitPlanLoading}
            fullWidth
            className="mt-5"
            text="Save"
            action={handleSave}
            color={'brand3'}
          />
        </>
      )}
    </>
  )
}
const AssignLicense = ({ open, onOpenChange }) => {
  const productResponse = useAPI('/api/products-list')
  return (
    <Drawer open={open} onOpenChange={onOpenChange} title="Edit User Details" width="28rem">
      <DrawerContent onOpenChange={onOpenChange} plan={productResponse.data?.products} />
    </Drawer>
  )
}

export default AssignLicense
