import { Fragment, useContext, useState, useEffect } from 'react'
import { ViewContext, Card, AccountNav, Table, Animate, TitleRow, usePermissions } from 'components/lib'
import AddEditConcepts from './addEditConcepts'
import { useSearchParams } from 'react-router-dom'
import MultiSelect from 'components/form/multiSelect/multiSelect'
import { practiceAreas } from '@lawcyborg/packages'
import useCallAPI from 'components/hooks/callApi'

export function Concepts(props) {
  const context = useContext(ViewContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const permissions = usePermissions()
  // TODO: if permission is not OWNER,them return from here

  const practiceAreaOptions = Object.entries(practiceAreas).map(([key, item]) => ({
    label: item.name,
    value: key,
  }))
  const [practiceArea, setPracticeArea] = useState(practiceAreaOptions[0].value ?? 'tax')
  const [concepts, setConcepts] = useState([])
  const practiceAreaId = practiceAreas[practiceArea].id
  const [getConcepts, , loading] = useCallAPI({
    url: `/api/account/concepts?practice_area_id=${practiceAreaId}`,
  })

  // Load data for selected practice area
  const callGetConcepts = () => {
    getConcepts({
      onSuccess: (res) => {
        const filter = res?.data?.concepts?.map((concept) => ({
          ...concept,
          children: concept.topics.map((individualTopic) => ({
            topic: individualTopic.topic,
            weighting: individualTopic.weighting,
          })),
        }))
        setConcepts(filter)
      },
    })
  }
  useEffect(() => {
    callGetConcepts()
  }, [practiceArea])

  const handleAddEditConcept = (concept) => {
    if (!concept?.id) return
    searchParams.set('concept', JSON.stringify(concept))
    searchParams.set('manageConcept', 'true')
    setSearchParams(searchParams)
  }

  // Delete Concept
  function handleDeleteConcept(concept, callback) {
    context.modal.show(
      {
        title: 'Delete Concept',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete "${concept.reason_included}"?`,
        url: `/api/account/concepts/${concept.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`Concept deleted successfully`, 'success', true)
        callback()
      }
    )
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <TitleRow title={`${practiceAreaOptions?.find((x) => x.value === practiceArea)?.label} Core Concepts`}>
          <div className="flex gap-2 items-center flex-col sm:flex-row">
            <MultiSelect
              singleSelect
              clearable={false}
              options={practiceAreaOptions || []}
              className="!mb-0 [&>button]:h-[46px] w-[20rem]"
              selectedValues={[practiceArea]}
              onValueChange={(value) => setPracticeArea(value[0])}
              placeholder="Select Practice Area"
            />
            <AddEditConcepts selectedPracticeArea={practiceAreaId} onClose={callGetConcepts} />
          </div>
        </TitleRow>

        <Card>
          <Table
            search
            collapsible
            className="restrict-width"
            data={concepts}
            loading={loading}
            show={['reason_included', 'context']}
            badge={{
              col: 'weightings',
              color: 'blue',
            }}
            actions={{
              edit: handleAddEditConcept,
              delete: handleDeleteConcept,
            }}
          />
        </Card>
      </Animate>
    </Fragment>
  )
}
