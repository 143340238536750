import { moduleIds } from '@lawcyborg/packages'
import { useMemo } from 'react'

const useAccess = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const featureFlags = user?.feature_flags
  const allowedModules = useMemo(() => {
    const licenseFeatures = user?.userLicenses || []
    const userModules = licenseFeatures.map((feature) => feature.module_id)
    const essentialModules = [moduleIds.ESSENTIAL_PLAN]
    const eliteModules = [moduleIds.ESSENTIAL_PLAN, moduleIds.ELITE_PLAN]

    const hasEssentialPlan = userModules.includes(moduleIds.ESSENTIAL_PLAN)
    const hasElitePlan = userModules.includes(moduleIds.ELITE_PLAN)
    const getPlanModules = () => {
      if (hasElitePlan) return eliteModules
      if (hasEssentialPlan) return essentialModules
      return []
    }

    const planModules = getPlanModules()

    const allModules = [...new Set([...userModules, ...planModules])]
    return allModules.reduce((acc, moduleId) => {
      acc[moduleId] = true
      return acc
    }, {})
  }, [user])

  const hasAccess = (moduleId, flag) => {
    if (typeof moduleId === 'object') {
      return moduleId.some((item) => allowedModules.hasOwnProperty(item))
    }
    if (allowedModules.hasOwnProperty(moduleIds.NZ_PRACTICE_AREA_BUNDLE)) return true
    if (!moduleId && !flag) return false
    if (moduleId && !allowedModules[moduleId]) return false
    if (flag) return !!featureFlags?.[flag]

    return true
  }

  return hasAccess
}

export { useAccess }
