import { Icon } from 'components/lib'
import React from 'react'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'

const DownloadablePartWrapper = React.memo(({ onClick = () => {}, type = '', children }) => {
  return (
    <div className="text-pretty">
      <Tippy touch={false} content={`Download ${type}`} animation="shift-away" arrow={roundArrow} inertia={true}>
        <div className="inline top-0 float-right ml-2 cursor-pointer" onClick={onClick}>
          <Icon image="download" size={24} color="grey" className="hover:text-black/80" />
        </div>
      </Tippy>
      {children}
    </div>
  )
})

export default DownloadablePartWrapper
